/**
 * TO DO
 * rever waiting depois do reconhecimento
 * fazer preloading do video que vai ser utilizado via grid
 * scroll
 * responsividade dos sequence
 * ver share
 *
 */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from "react-router-dom";
import { isMobile, isDesktop, isIOS } from 'react-device-detect'
import Vimeo from '../vimeo/index.js';
import { csv } from "d3-fetch";
import download from 'downloadjs';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";
import waveicon from "../../assets/img/ondaBIGTRANSP.gif";
import arrowIcon from "../../assets/img/dnaBIGTRANSP.gif";
import iconDna from "../../assets/img/icon-dna.png";
import iconReload from "../../assets/img/icon-reload.png";
import iconShare from "../../assets/img/icon-share.png";
import iconX from "../../assets/img/X.png";
import cardOverlayPT from "../../assets/img/card-overlay.png";
import cardOverlayENG from "../../assets/img/card-overlay-EN.png";

import GENRE_MAP from "../../assets/csv/genremap.csv";
import SUBGENRE_MAP from "../../assets/csv/subgenremap.csv";
import i18n from "../../locales/i18n.config";

const unkown = i18n.locale == 'pt_BR' ? "Desconhecido" : "Unknown";
const defaultData = {
    title: unkown,
    artist: unkown,
    score: "0",
    genre: unkown,
    img: null,
    html: null,
}


const AudioRecording = () => {
    const [audioLevel, setAudioLevel] = useState(0);
    const [audioStream, setAudioStream] = useState(null);
    const [recordingBlob, setRecordingBlob] = useState(new Blob());
    const [mediaRecorder, setMediaRecorder] = useState(undefined);
    const [response, setResponse] = useState(defaultData);
    const [phase, setPhase] = useState("idle");
    const [score, setScore] = useState(0);
    const [subTitleListening, setSubTitleListening] = useState(i18n.t("analyzing.waiting"));
    const [resultAvaiable, setResultAvaiable] = useState(false);
    const [endAnalyzing, setEndAnalyzing] = useState(false);
    const [sharing, setSharing] = useState(false);
    const [autoTime, setAutoTime] = useState(null);
    const [bgMap, setBgMap] = useState([]);
    const [genreMap, setGenreMap] = useState([]);
    const [responseCardBg, setResponseCardBg] = useState(null);
    const [showWarning, setShowWarning] = useState(false);
    const [downloadImage, setDownloadImage] = useState(null);
    const [popUpDownload, setPopUpDownload] = useState(false);

    const sendBtn = useRef();
    const shareRef = useRef(null);
    const shareImage = useRef(null);

    const reload = () => {
        setEndAnalyzing(false);
        setResultAvaiable(false);
        setResponse(defaultData);
        shareImage.current = null;
        setPhase('idle');
        setSubTitleListening("Aguarde alguns segundos");
    }

    const startRecording = () => {
        setShowWarning(false)
        navigator.mediaDevices.getUserMedia({
            audio: {
                noiseSuppression: false,
                echoCancellation: false
            }
        })
            .then(handleSuccess)
            .catch(handleError);
        setPhase('listening');
        console.log('start');
    };

    const stopRecording = () => {
        if (phase !== "listening") return;
        clearTimeout(autoTime);
        console.log('stop')
        mediaRecorder?.stop();
        audioStream?.getTracks().forEach(track => track.stop());
        setAudioStream(null);
    };

    const handleSuccess = (stream) => {
        setAutoTime(setTimeout(() => sendBtn.current.click(), 10000))
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        recorder.start();

        recorder.addEventListener("dataavailable", (event) => {
            setRecordingBlob(event.data);
            recorder.stream.getTracks().forEach((t) => t.stop());
            setMediaRecorder(undefined);
        });

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);

        microphone.connect(analyser);

        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);


        const updateAudioData = () => {
            analyser.getByteFrequencyData(dataArray);

            // Calculate average audio level
            const sum = dataArray.reduce((acc, value) => acc + value, 0);
            const average = sum / bufferLength;

            // Update state with the average audio level
            setAudioLevel(average);

            // Call the function recursively for real-time updates
            requestAnimationFrame(updateAudioData);
        };

        // Start updating audio data
        updateAudioData();
        setAudioStream(stream);

    };

    const handleError = (error) => {
        console.error('Error accessing microphone:', error);
    };

    const getStep = val => {
        const n = parseFloat(val).toFixed(1);
        const f = Math.floor(n);
        return f < 16 ? 0 :
            f < 31 ? 16 :
                f < 46 ? 31 :
                    f < 61 ? 46 :
                        f < 76 ? 61 :
                            f < 91 ? 76 : 91
    }

    const fixScore = (val) => {
        const n = parseFloat(val).toFixed(1);
        setScore(Math.floor(n));
    }

    

    const fixBgType = (subgenre, step, phase) => {
        console.log(subgenre, step, phase)
        if (step == 100) {
            setPhase(phase);
            setResponseCardBg(932066878);
            return;
        }
        console.log(subgenre, step)
        var genre = genreMap.find(g => g.subgenre == subgenre)
        console.log(genre)
        genre = typeof genre === "undefined" ? genre = "generic" : genre.genre;
        console.log(genre)
        var bg = bgMap.find(v => v.genre == genre && v.score == step);
        console.log("video id", bg, bg.id)
        setPhase(phase);
        setResponseCardBg(bg.id);
    }
    const sendAudioFile = async () => {
        console.log('analisando');
        setPhase('analyzing');
        setResultAvaiable(false);
        try {
            const response = await fetch(
                i18n.t("system.20mind"),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                    body: recordingBlob,
                }
            );
            if (response.ok) {
                console.log('resposta ok');
                const data = await response.json();
                console.log(data)
                setResponse(data);
                fixScore(data.score);
                fixBgType(data.genre, data.score == 100 ? 100 : getStep(data.score), 'success');
                try {
                    await fetch(data.img)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], 'rock-dna.jpg', { type: blob.type });
                            setDownloadImage(file);
                            shareImage.current = {
                                url: data.html,
                                text: 'Descobri que a influência da Sister Rosetta Tharpe na música ' + data.title + ' de ' + data.artist + ' é de ' + data.score + '%! Curioso(a) sobre a sua? Confira e compartilhe seus resultados!',
                            }
                        });
                } catch {
                    setResponse(defaultData);
                    console.log('fail');
                    setScore(0);
                    fixBgType('desconhecido', getStep(0), 'fail');
                    console.error(response.statusText);
                }
            } else {
                setResponse(defaultData);
                console.log('fail');
                setScore(0);
                fixBgType('desconhecido', getStep(0), 'fail');
                console.error(response.statusText);
            }
        } catch (error) {
            console.error(error);
        } finally {
            console.log('esperando');
            setRecordingBlob(new Blob());
            setResultAvaiable(true);
        }
    }
    const downloadShare = () => {
        if (!downloadImage) return;
        download(downloadImage, "rockdna", "image/jpg");
        if (isIOS) {
            setPopUpDownload(true);
        }
    }
    const share = useCallback(() => {
        if (shareRef.current === null || shareImage.current === null) {
            return
        }
        setSharing(true)
        if (navigator.canShare(shareImage.current)) {
            try {
                navigator.share(shareImage.current)
                console.log('share suc')
            } catch (err) {
                if (err.name !== 'AbortError') {
                    console.error(err.name, err.message)
                }
            } finally {
                console.log('finaly')
                setSharing(false);
            }
        } else {
            console.warn('Sharing not supported', shareImage.current)
            setSharing(false);
        }
    }, [shareRef, sharing, shareImage])

    /*const card2Image = (response) => {
        if (shareImage.current !== null) return
        //const fontEmbedCss = getFontEmbedCSS(shareRef.current);
        const filter = (node) => {
            const exclusionClasses = ['bgvideo'];
            return !exclusionClasses.some((classname) => node.classList?.contains(classname));
        }
        toBlob(shareRef.current, {
            filter: filter,
            //fontEmbedCss,
            cacheBust: true,
            style: {
                "border": "0",
                "border-radius": "0",
                "width": "720px",
                "max-width": "720px",
                "height": "900px",
                "maxHeight": "900",
                "padding": 0,
            },
        })
            .then((blob) => {
                const file = new File([blob], 'image.png', { type: blob.type });
                setDownloadImage(file)
                console.log(blob.type)

                shareImage.current = isIOS ? {
                    url: "https://rosetta.20dash.com/",
                    text: 'Descobri que a influência da Sister Rosetta Tharpe na música ' + response.title + ' de ' + response.artist + ' é de ' + response.score + '%! Curioso(a) sobre a sua? Confira e compartilhe seus resultados!',
                } : {
                    url: "https://rosetta.20dash.com/",
                    text: 'Descobri que a influência da Sister Rosetta Tharpe na música ' + response.title + ' de ' + response.artist + ' é de ' + response.score + '%! Curioso(a) sobre a sua? Confira e compartilhe seus resultados!',
                    files: [file]
                }
            })
    }
*/
    useEffect(() => {
        if (!recordingBlob || recordingBlob?.size == 0) return;
        sendAudioFile();
    }, [recordingBlob])

    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setSubTitleListening(subTitleListening === i18n.t("analyzing.waiting") ? i18n.t("analyzing.parameters") : i18n.t("analyzing.waiting"));
        }, 3000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, [subTitleListening]);

    useEffect(() => {
        if (endAnalyzing && resultAvaiable && ["analyzing", "fail", "success"].includes(phase))
            setPhase("result");
    }, [endAnalyzing, resultAvaiable, phase]);

    useEffect(() => {
        csv(GENRE_MAP)
            .then((text) => {
                const bgs = text
                    .map((v) => ({
                        genre: v.genre.toString(),
                        score: v.score,
                        id: v.id
                    }))
                setBgMap(bgs);
            })
            .catch((error) => console.log(error));

        csv(SUBGENRE_MAP)
            .then((text) => {
                const genres = text
                    .map((v) => ({
                        subgenre: v.subgenre.toString(),
                        genre: v.genre.toString(),
                    }))
                setGenreMap(genres);
            })
            .catch((error) => console.log(error));
    }, [])

    return (
        <main className='position-relative' id="main-section">
            {phase === "idle" && <section className='logo-cta'>

                {(isDesktop && showWarning) && <div className='backdrop'>
                    <div className='warning'>
                        <p>{i18n.t("popup.message")}</p>
                        <button onClick={startRecording}>{i18n.t("popup.button")}</button>
                    </div>
                </div>}
                {/*<img src={logoHome} className='static-logo' />*/}
                <Vimeo video="930808442" className='static-logo' />
                
                <div className='record-controll'>
                    <h1 className="preline">{i18n.t("home.main")}</h1>
                    <button className="rec-button" onClick={isDesktop ? () => {
                        setShowWarning(true)
                    } : startRecording}>
                        {i18n.t("home.start")}
                        <span className='absIcon' style={{ backgroundImage: `url(${iconDna})` }}></span>
                    </button>
                </div>
            </section>
            }
            {/*phase == "listening" && <Audiowave volume={audioLevel / 20}/>*/}
            {phase == "listening" && <section className='listening'>
                <Vimeo video="931168450" className='logo-interno' />
                <h1 className='sectionTitle'>{i18n.t("analyzing.music")}</h1>
                <h2 className='sectionSubtitle'>{subTitleListening}...</h2>
                <div className="waves-video">
                    <Vimeo video="931820901" />
                </div>
                {/*<div className='waves-wrapper' style={{ backgroundImage: `url(${partitura})` }}>

                    <ParticleWave numParticles={isMobile ? audioLevel : audioLevel * 2.5} />
    </div>*/}

                <footer className="sectionFooter">
                    {isDesktop &&
                        <div className=''>{i18n.t("analyzing.compare")}</div>}
                    <img src={waveicon} className='footerImg'/>
                    {/*<Vimeo video="931577021" className='footerImg' />*/}
                    <Link reloadDocument className="stop-button">
                        {i18n.t("analyzing.cancel")}
                        <span className='absIcon' style={{ backgroundImage: `url(${iconX})` }}></span>
                    </Link>
                    <img src={arrowIcon} className='footerImg'  />
                    {/*<Vimeo video="931577011" className='footerImg' />*/}
                    {isDesktop && <div className=''>{i18n.t("analyzing.examinating")}</div>}
                    {!isDesktop && <div className='last-phrase'>{i18n.t("analyzing.examinating")}</div>}
                </footer>
                <i onClick={stopRecording} ref={sendBtn} className='invisbtn' />
            </section>}
            {["analyzing", "fail", "success"].includes(phase) && <section className="analyzing">
                <Vimeo video="931168450" className='logo-interno' />
                <h1 className='sectionTitle'>{i18n.t("results.analyzing")}</h1>
                <h2 className='sectionSubtitle'>{i18n.t("results.generating")}</h2>
                <Vimeo video="931168351" className='dnaguitar'
                    onPlay={() => setTimeout(() => setEndAnalyzing(true), 4000)} />
                {/*<img src={dnaGuitarGif} className='dnaguitar' />
                {/*<video className='dnaguitar' autoPlay loop muted playsInline disablePictureInPicture>
                    {isSafari ? <source src={guitarraMov} type='video/mp4; codecs="hvc1"' /> :
                        <source src={guitarraWebm} type='video/webm' />}
        </video>*/}
            </section>}
            {phase == "result" && <>
                <section className="result">
                    <header>
                        <h2 className="pre">{i18n.t("results.ready")}</h2>
                        <p className=''>{i18n.t("results.obtain")}</p>
                        {/*<img src={logointerno} className='hidden lg:block' />*/}
                        {isDesktop && <Vimeo video="931168450" className='logo-interno' />}
                    </header>
                    {/*showResponse && */}
                    <article className={`response-card ${response.genre}`} id="response-card" ref={shareRef}>
                        {responseCardBg && <Vimeo video={responseCardBg} className='bgvideo' />}
                        {/*<video className='bgvideo' onPlay={() => card2Image(response)} autoPlay loop muted playsInline={true} disablePictureInPicture={true}>
                            <source src={bgVideo} type='video/mp4' />
    </video>*/}
                        <img src={i18n.locale == "pt_BR" ? cardOverlayPT: cardOverlayENG} className='card-overlay'/>
                        <div className={`incidencia adjust`}>
                            <strong>{score < 10 && <>&nbsp;</>}{score < 100 && <>&nbsp;</>}{score}<small>%</small></strong>
                            {/*<strong>{score}<small>%</small></strong>*/}
                            {/*<strong>{score}{(decimal && decimal > 0) && <>.<small>{decimal} </small></>}<small>%</small></strong>*/}
                        </div>
                        <footer>
                            <span>{response.artist}</span><br />
                            <span>{response.title}</span>
                        </footer>
                    </article>

                    <nav className='toolBar'>
                        {/*<button className="share-button" onClick={share} disabled={(sharing || shareImage.current === null)}>
                            {(sharing || shareImage.current === null) && <span className='loading-icon'>⟳</span>} Compartilhar
                            <span className='absIcon' style={{ backgroundImage: `url(${iconShare})` }}></span>
</button>*/}
                        {isMobile && <button className="share-button" onClick={share}
                            disabled={(sharing || shareImage.current === null)}>
                            {(sharing || shareImage.current === null) &&
                                <span className='loading-icon'>⟳</span>}  {i18n.t("results.share")}
                            <span className='absIcon' style={{ backgroundImage: `url(${iconShare})` }}></span>
                        </button>}
                        {isDesktop && <div>
                            <FacebookShareButton disabled={(shareImage.current === null)} url={response.html}>
                                <FacebookIcon size={32} round bgStyle={{ fill: 'rgb(222, 136, 98)' }} />
                            </FacebookShareButton>
                            <TwitterShareButton disabled={(shareImage.current === null)} url={response.html}
                                round={true}>
                                <TwitterIcon size={32} round={true} bgStyle={{ fill: 'rgb(222, 136, 98)' }} />
                            </TwitterShareButton>
                        </div>}
                        <button className="download-button" onClick={downloadShare} disabled={(downloadImage === null)}>
                            {downloadImage === null && <span className='loading-icon'>⟳</span>} {i18n.t("results.download")}
                            <span className='absIcon' style={{ backgroundImage: `url(${iconShare})` }}></span>
                        </button>
                        <button className="reload-button" onClick={reload}>
                            {i18n.t("results.restart")}
                            <span className='absIcon' style={{ backgroundImage: `url(${iconReload})` }}></span>
                        </button>
                    </nav>
                    {/*<video className='logointerno' autoPlay loop muted playsInline disablePictureInPicture>
                        {isSafari ? <source src={logoinMov} type='video/mp4; codecs="hvc1"' /> :
                            <source src={logoinWebm} type='video/webm' />}
    </video>*/}

                    {isMobile && <Vimeo video="931168450" className='logo-interno' />}

                </section>
            </>}
            {popUpDownload && <div className='backdrop'>
                <div className='warning warning-download'>
                    <p>{i18n.t("popup.download")}</p>
                    <button onClick={()=>{setPopUpDownload(false)}}>{i18n.t("popup.button")}</button>
                </div>
            </div>}
        </main>
    );
};

export default AudioRecording;
