import React, { useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
import eventNames from './eventNames';

const Vimeo = (props) => {
    const container = useRef(null)

    const getInitialOptions = () => {
        const { video } = props;
        const videoType = /^https?:/i.test(video) ? 'url' : 'id';
        return {
            [videoType]: video,
            width: props.width,
            height: props.height,
            autopause: props.autopause,
            autoplay: props.autoplay,
            byline: props.showByline,
            color: props.color,
            controls: props.controls,
            loop: props.loop,
            portrait: props.showPortrait,
            title: props.showTitle,
            muted: props.muted,
            background: props.background,
            responsive: props.responsive,
            dnt: props.dnt,
            speed: props.speed,
            keyboard: props.keyboard,
            pip: props.pip,
            playsinline: props.playsInline,
            quality: props.quality,
            texttrack: props.textTrack,
            transparent: props.transparent,
        };
    }

    useEffect(() => {
        const { start, volume, playbackRate, onError, onReady  } = props;

        const player = new Player(container.current, getInitialOptions());

        Object.keys(eventNames).forEach((dmName) => {
            const reactName = eventNames[dmName];
            player.on(dmName, (event) => {
                // eslint-disable-next-line react/destructuring-assignment
                const handler = props[reactName];
                if (handler) {
                    handler(event);
                }
            });
        });
        player.on('play', e => {
            //container.current.style.opacity = "1"
            //setTimeout(()=>container.current.style.opacity = "1", 200)
        })
        player.ready().then(() => {
            if (onReady) {
                onReady(player);
            }
        }, (err) => {
            if (onError) {
                onError(err);
            } else {
                throw err;
            }
        });

        if (typeof start === 'number') {
            player.setCurrentTime(start);
        }

        if (typeof volume === 'number') {
            this.updateProps(['volume']);
        }

        if (typeof playbackRate === 'number') {
            this.updateProps(['playbackRate']);
        }

    }, [props, container])
    
    /*<div ref={container} className={props.className} style={{opacity: "0"}}></div>*/
    
    return (
        <div ref={container} className={props.className} ></div>
    );
};
export default Vimeo;