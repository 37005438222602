import { Routes, Route } from 'react-router-dom';

import ReactGA from 'react-ga4';

import Home from "./screens/Home";

import Vimeo from './components/vimeo';
import LowBattery from './components/LowBattery';

export default function App() {
  ReactGA.initialize('G-1SRGK595Y3');
  return (
    <>
      <Vimeo video="933828216" className='masterbgvideo' background={true} dnt={true} muted={true} autoplay={true}/>
      <LowBattery />
      <div className={`App`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:lang" element={<Home />} />
        </Routes>
      </div>
    </>
  );
}
