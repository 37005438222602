import {useEffect, useMemo, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";


//assets
import LogoImg from "../../assets/img/logo.png";
import billboard from "../../assets/img/logo_billboard.svg";
import i18n from "../../locales/i18n.config";

export default function Menu() {
    const [open, setOpen] = useState(false);
    let {lang} = useParams();
    const langTar = lang === 'pt' ? 'EN' : 'PT';

    const topPage = () => {
        window.scrollTo(0, 0);
    }

    const changeLang = () => {
        const tar = lang === 'pt' ? 'en' : 'pt';
        window.location.href = window.location.protocol + "//" + window.location.host + '/' + tar
    }


    console.log(lang)
    return (
        <nav className="menu">
            <div className="left-menu">
                <Link reloadDocument onClick={topPage} className="logo-anchor">
                    <img src={LogoImg} className="logo-img"/>
                </Link>
                <div>
                    <img src={billboard} className="billboard-img"/>
                    {<a className="link-rosetta" href="https://billboard.com.br/biografia-sister-roseta/">Sister Rosetta Tharpe</a>}
                    
                </div>
            </div>

            <div className="right-menu">
                <span className="langswitch">
                    <span className={`${lang==='pt' && 'active'}`} onClick={changeLang}>PT</span>
                    <span className={`${lang==='en' && 'active'}`} onClick={changeLang}>EN</span>
                </span>
                <div className="dropdown">
                <span onClick={() => setOpen(!open)}>
                    <hr className="stripe"/>
                    <hr className="stripe"/>
                </span>
                    <ul className={`dropdown-menu ${open && "active"}`}>
                        {/*<li><a onClick={changeLang}>{langTar}</a></li>*/}
                        <li><Link reloadDocument onClick={topPage}>{i18n.t("menu.test")}</Link></li>
                        <li><a href="#sobre">{i18n.t("menu.sr")}</a></li>
                        <li><a href="#projeto">{i18n.t("menu.project")}</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
