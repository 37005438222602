import { useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import sourcevideo from '../../assets/videos/240x2401c1c1c.mp4';
import i18n from '../../locales/i18n.config';

export default function LowBattery() {
    const videoRef = useRef(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        videoRef?.current && videoRef?.current?.addEventListener('suspend', () => {setShow(true)})
    }, [])

    return isIOS && (
        <>
            <video className="hidden" src={sourcevideo} autoPlay muted playsInline type="video/mp4" ref={videoRef} />
            {show && <div className='backdrop'>
                <div className='warning lowbattery'>
                    <p>{i18n.t("popup.lowbattery")}</p>
                    <button onClick={() => setShow(false)}>{i18n.t("popup.button")}</button>
                </div>
            </div>}
        </>
    );
}
