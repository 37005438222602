import Menu from "../components/menu/Menu";
import AudioListener from "../components/AudioListener/";
import ScrollingContent from "../components/ScrollingContent/";
import ReactGA from 'react-ga4';


export default function Home(props) {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Home"
    })
    console.log(props.lang)
    return (
        <>
            <div className="bg-main">
                <Menu />
                <AudioListener />
                <ScrollingContent />
            </div>
        </>
    );
}
