import React, { useState, useEffect, useRef } from 'react';
import { isMobile, isDesktop } from 'react-device-detect';
import Vimeo from '../vimeo';
import useVisible from "../../hooks/useIsVisible";
import img1 from "../../assets/img/1vitrola.jpg";
import iconDna from "../../assets/img/icon-dna.png";
import i18n from "../../locales/i18n.config";

const ScrollingContent = () => {

    const [currentImage, setCurrentImage] = useState(img1);
    const [step, setStep] = useState(0);
    const [lastScroll, setLastScroll] = useState(null)
    const elemRef = useRef();
    const isVisible = useVisible(elemRef);
    /*
        var scro = 0;
        const handleScroll = e => {
            console.log(window.scrollY, document.documentElement.scrollTop, lastScroll, scro)
            var st = window.scrollY || document.documentElement.scrollTop;
            if (st == scro) return;
            if (st > scro) {
                if (step == 3) return;
                setStep(step + 1);
                console.log('down')
            } else {
                if (step == 0) return;
                setStep(step - 1);
                console.log('up')
            }
            scro = scro == 0 ? st : (st <= 0) ? 0 : st;
        }

        useEffect(() => {
            if (isVisible) {
                window.addEventListener('scroll', handleScroll);
                console.log('visible')
            } else {
                window.removeEventListener('scroll', handleScroll);
                console.log('invisible')
            }
        }, [isVisible])
    */
    return (
        <>
            <article id="sobre" className='scroll-section' ref={elemRef}>
                {/*<div className='wrapper-slide-scroll'>
                    <div className='negative-margin'>
                        <Slide assets={[img1, img2, img3, img4]} autoSlide={true} />
                    </div>
                </div>*/}
                <Vimeo video="931168248" className='responsive-ilus' />
                <main>
                    {isMobile && <a href="https://billboard.com.br/biografia-sister-roseta/" className="underline-link">Conheça Mais</a>}
                    <h1 className=''>{i18n.t("home.srtTitle")}</h1>
                    <p href={1}>
                        {i18n.t("home.srtP1")}
                    </p>
                    <p href={2}>
                        {i18n.t("home.srtP2")}
                    </p>
                    <p href={3}>
                        {i18n.t("home.srtP3")}
                    </p>
                    <p href={4}>
                        {i18n.t("home.srtP4")}
                    </p>
                    {isDesktop && <a href="https://billboard.com.br/biografia-sister-roseta/" className="underline-link">
                        Conheça Mais
            </a>}
                </main>
                {/*<footer className='flex justify-between scroll-footer lg:hidden'>
                    <a href="./" className="underline-link">
                        <span className='absIcon' style={{ backgroundImage: `url(${iconPen})` }}></span>
                        Conheça Mais
                    </a>
                    <img src={logoBilboard} className='logo-billboard' />
            </footer>*/}
            </article >
            <article id="projeto" className='scroll-section'>
                {/*<div className='wrapper-slide-scroll'>
                    <div className='negative-margin'>
                        <Slide assets={[img6]} disableClick={true} />
                    </div>
        </div>*/}
                <Vimeo video="930808442" className='responsive-ilus' />
                <main>
                    {/*<h1>
                        Esse projeto demonstra o profundo impacto que Sister Rosetta Tharpe
                        teve no rumo do Rock 'n Roll. Seus artistas favoritos foram influenciados por ela e agora você pode enxergar quanto.
                        <br /><br />
                        <small>
                            - Gayle Wald,<br />
                            autora da biografia de Sister Rosetta Tharpe
                        </small>
    </h1>*/}
                    <p>
                        {i18n.t("home.srt2P1")}
                    </p>
                    <p>
                        {i18n.t("home.srt2P2")}
                    </p>

                    <p>
                        {i18n.t("home.srt2P3")}

                    </p>

                    <footer className='scroll-footer'>
                        <a href='#main-section' className="rec-button">

                            {i18n.t("home.top")}
                            <span className='absIcon' style={{ backgroundImage: `url(${iconDna})` }}></span>
                        </a>
                    </footer>


                </main>
            </article >
        </>
    );
};

export default ScrollingContent;
