import { I18n } from "i18n-js";
import { useLocation } from "react-router-dom";
import pt_BR from "./pt_BR.json";
import en from "./en.json";

const i18n = new I18n({
    pt_BR: pt_BR,
    en: en
});

i18n.locale = window.location.pathname.startsWith('/pt') ?  "pt_BR" : "en";

export default i18n;
